import axios from "./index";


export const GET = (
  endpoint,
) => {
  return axios.get(endpoint)
};

export const POST = (
  endpoint,
  body,
) => {
  
  return axios.post(endpoint,body)
};

export const DELETE = (
  endpoint,
  body,
) => {
  return axios.delete(endpoint,body)
};

export const PUT = (
  endpoint,
  body,
) => {
  return axios.put(endpoint,body)
};

