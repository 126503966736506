import appLocaleData from "react-intl/locale-data/vi";
import enMessages from "../locales/vn_VN";

const VnLang = {
  messages: {
    ...enMessages,
  },
  locale: "vi-VN",
  data: appLocaleData,
};
export default VnLang;
