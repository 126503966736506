import { CHANGE_LOCALE, SET } from "../actions";

export const changeLocale = (locale) => {
    
  localStorage.setItem("currentLanguage", locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

export const changeState = (responsive) => {
    
  return {
    type: SET,
    payload: responsive,
  };
};
