import { defaultLocale, localeOptions } from "src/helpers/defaultValues";
import { CHANGE_LOCALE, SET } from "../actions";

const INIT_STATE = {
  locale:
    localStorage.getItem("currentLanguage") &&
    localeOptions.filter(
      (x) => x.id === localStorage.getItem("currentLanguage")
    ).length > 0
      ? localStorage.getItem("currentLanguage")
      : defaultLocale,
  sidebarShow: "responsive",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case SET:
      return { ...state, sidebarShow: action.payload };
    default:
      return { ...state };
  }
};
