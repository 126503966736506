import { notification } from "antd";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URI + "/admin"

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(
  async function (config) {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    console.log("config", config);
    return config;
  },

  function (error) {
    console.log("error", error);

    return Promise.reject(error);
  }
);
instance.interceptors.response.use( (response) => checkStatus(response),
  (error) => Promise.reject(checkStatus(error.response))
);
function logout() {
  localStorage.removeItem("token");
}

function checkStatus(response) {
  if (response?.status >= 200 && response?.status < 300) {
    return response;
  }
  if(response?.status > 300 ) {
    notification.error({
      message: `Notification`,
      description:response.data.message,
    });
  }
  if (response?.status === 401) {
    logout();
    window.location.reload();
  }

  return response?.data;
}
export default instance
