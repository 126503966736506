export const defaultLocale = "en";
export const defaultNameLanguage = "Vietnamese";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  // { id: "vn", name: "Vietnamese", direction: "ltr" },
  { id: "cn", name: "Chinese", direction: "ltr" },
];


export const defaultDirection = "ltr";
