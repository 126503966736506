import React from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
// import { ToastContainer } from "react-toastify";
import history from "./history";
import AppLocale from "./lang";
import "./statics/scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
const Register = React.lazy(() => import("./views/auth/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  const locale = useSelector((state) => state.settings.locale);
  const currentAppLocale = AppLocale[locale];
  return (
    <div className="h-100">
      {/* <ToastContainer /> */}

      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        {/* <HashRouter> */}
        <BrowserRouter history={history}>
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper">
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              <Route path="*" component={Page404} />

              </Switch>
            </React.Suspense>
          </AnimatedSwitch>
        </BrowserRouter>
      </IntlProvider>
    </div>
  );
}
export default App;
