import { addLocaleData } from "react-intl";
import enLang from "./entries/en-US";
import vnLang from "./entries/vn-VN";
import cnLang from "./entries/zh-CN";

const AppLocale = {
  en: enLang,
  vn: vnLang,
  cn: cnLang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.vn.data);
addLocaleData(AppLocale.cn.data);

export default AppLocale;
