import appLocaleData from 'react-intl/locale-data/zh';
import esMessages from '../locales/zh-CN';

const EsLang = {
    messages: {
        ...esMessages
    },
    locale: 'zh-CN',
    data: appLocaleData
};
export default EsLang;