import { call, put, takeEvery } from "redux-saga/effects";
import {
  LOGIN_USER,
  LOGOUT_USER,

} from "./constant";

import {
  loginUserSuccess,
  loginUserError,

} from "./actions";
import { loginService, logoutService } from "./services";

function* loginUser({ payload }) {
  try {
    const loginUser = yield call(loginService, payload.user);
    const { access_token } = loginUser.data;
    localStorage.setItem("token", access_token);
    yield put(loginUserSuccess(loginUser.data));
    const { history } = payload;
    history.push("/");
  } catch (error) {
    console.log("error", error);

    yield put(loginUserError(error));
  }
}

function* logout({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(logoutService);
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    history.push("/login");

  } catch (error) {}
}

export default [
  takeEvery(LOGIN_USER, loginUser),
  takeEvery(LOGOUT_USER, logout),
 
];
